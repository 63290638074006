<template>
    <div class="d-flex flex-wrap">
        
        <div>
            <div :id="'popover_' + _uid.toString() + contact.id_contact_data_main.toString()" tabindex="0" @click="openPopover()" class="b-avatar avatar avatar-sm rounded-circle badge-secondary rounded-circle app-local-avatar" v-bind:style="{'background-color': colors[contact.color_index]}">
                {{contact.shortcut_name}}
            </div>
            <div class="d-flex">
                <div class="app-local-badge" v-if="contact.planning == '1'"></div>
                <div class="app-local-badge" v-if="contact.hours == '1'" style="background-color: #039852;"></div>
                <div class="app-local-badge" v-if="contact.placement == '1'" style="background-color: #51B5E0;"></div>
            </div>

        </div>
        
        <b-popover v-if="popover_show" :target="popover_target"  triggers="focus" no-fade :show.sync="popover_show" placement="top" @hide="popoverHide">
            
            <template #title>
                {{contact.first_name + ' '+ contact.inbetween_name + ' ' + contact.last_name }}
                <button type="button" class="close" @click="onClose" aria-label="Close">
                    <span>×</span>
                </button>
            </template>

            <b-row class="align-items-center">
                <b-col cols="auto">
                    <div class="b-avatar avatar avatar-sm rounded-circle badge-secondary rounded-circle font-weight-bolder" v-bind:style="{'background-color': colors[contact.color_index]}">
                        {{contact.shortcut_name}}
                    </div>
                    <div class="d-flex">
                        <div class="app-local-badge-popover" v-if="contact.planning == '1'"></div>
                        <div class="app-local-badge-popover" v-if="contact.hours == '1'" style="background-color: #039852;"></div>
                        <div class="app-local-badge-popover" v-if="contact.placement == '1'" style="background-color: #51B5E0;"></div>
                    </div>

                </b-col>
                <b-col cols="auto">
                    <div class="mb-0 mr-3">
                        <p class="mb-0 app-popover-small-text">
                            <span class="font-weight-600"> {{contact.function}} </span> <br />
                            <span v-if="contact.planning == '1' || contact.hours == '1' || contact.placement == '1'" v-html="getRole(contact)"></span><br v-if="contact.planning == '1' || contact.hours == '1' || contact.placement == '1'"/>
                            <b-link class="text-nowrap" :href="'mailto:' + contact.email">{{contact.email}}</b-link> <br />
                            <b-link :href="'tel:' + phone">{{phone}}</b-link> 
                        </p>                        
                    </div>
                </b-col>
            </b-row>
           
           <hr class="mt-1 mb-2" v-if="canDisconnect" />

           <b-row v-if="canDisconnect">
               <b-col cols="6">
                    <p class="text-sm mb-0">
                        <span class="app-text-link" @click="onRemove(contact)">Ontkoppel</span>
                    </p>
               </b-col>
            </b-row>

        </b-popover>

    </div> 
</template>

<script>
import { color } from 'd3';

export default {
    components: {
        
    },

    props: {
        contact: { },     
        idDraggable: {type: Boolean, default: true},
        canDisconnect: {type: Boolean, default: false}
    },
        
    data(){
        return{
            colors: ['#1b3f68', '#56a9f0', '#348778', '#6ccc7e', '#1b3f68', '#56a9f0', '#348778', '#6ccc7e', '#1b3f68', '#56a9f0', '#348778', '#6ccc7e'],
            popover_show: false,
            popover_target: null,
            roles: [ this.trans('cp-planning',192), this.trans('sh-hours',215), this.trans('placment',5)]
        }
    },



     computed: {
        // cContacts: {
        //     get() { 
        //             var l_contacts = [];
        //             this.contacts.forEach(element => {
        //                 element.color_index = 0;
        //                 if(this.ids_contact_data_main.includes(element.id_contact_data_main.toString())) {
        //                     var last_element = (l_contacts.reverse()).find((item) => item.shortcut_name === element.shortcut_name);
        //                     if (last_element != null) {
        //                         element.color_index = last_element.color_index + 1;
        //                     }
        //                     l_contacts.push(element);
        //                 }
        //             });
        //             return l_contacts;
        //         }
        // },
        phone: function () {
            if (this.contact.phone_number !== null && this.contact.phone_prefix == '+31') {
                return 0 + this.contact.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
            } else {
                return this.contact.phone_prefix + " " + this.contact.phone_number;
            }
        }
    },

    methods: {
        openPopover() {
            if (this.popover_show == false) { 
                this.popover_target = 'popover_' + this._uid.toString() + this.contact.id_contact_data_main.toString();
                this.popover_show = true;
            }
        },
        popoverHide() {
             this.popover_target = null;
             this.popover_show = false;
        },

        onClose() {
            this.popover_show = false;
        },

        onRemove(event){
            this.$emit('onRemove', event);
        },

        startDrag (event, id_contact_data_main) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('id_contact_data_main', id_contact_data_main)
  		},

        getRole(contact){
            var role = [];

            if(contact.planning == '1') role.push(this.roles[0]);
            if(contact.hours == '1') role.push(this.roles[1]);
            if(contact.placement == '1') role.push(this.roles[2]);

            return role.join(', ');
        },

        roleCount(contact){
            var n = 0;

            if(contact.planning == '1') n++;
            if(contact.hours == '1') n++;
            if(contact.placement == '1') n++;

            return n;
        }


    }
};

</script>

<style>

.app-local-avatar {
    margin-left: calc(-0.1575rem); 
    margin-right: calc(-0.1575rem); 
    width: 28px; 
    height: 28px; 
    border: 2px solid white; 
    font-size: 0.7rem;
}

.app-local-avatar:hover{
    opacity: 0.7;
    cursor: pointer;
}

.tooltip{
    opacity: 1 !important;
    
}
.b-tooltip:not([style*='transform']) { top: 0; }   

.background-0{
    background: #348778 !important;
}

.background-1{
    background: #6ccc7e !important;
}

.background-2{
    background: #56a9f0 !important;
}

.app-local-badge{
    background-color:#ed8132;
    position: relative;
    top: -1px;
    margin-bottom: none;
    font-size: .5rem;
    border-radius: 8px;
    width: 8px;
    height: 8px;
    color:white;
    text-align: center;
    margin-top: -8px;
    margin-left: 0px;

}

.app-local-badge-popover{

    background-color:#ed8132;
    position: relative;
    top: 3px;
    margin-bottom: none;
    font-size: .5rem;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    color:white;
    text-align: center;
    margin-top: -12px;
    
}


</style>