<template>
    <div>
        <b-overlay :show="loading" :opacity="0.6" variant="transparent"> 
            
            <b-row class="app-local-contacts-avatar-component">
                <b-col cols="12" v-if="loading">
                    <b-card no-body class="p-3 mb-3 app-local-height" > 
                        <div class="d-flex" >                       
                            <div v-for="item in 5" :key="item" class="d-flex">
                                <div class="b-avatar avatar avatar-sm rounded-circle badge-secondary rounded-circle app-local-avatar">
                                </div>
                            </div>   
                        </div>                                   
                    </b-card>  
                </b-col> 

                <b-col cols="12" v-if="cIdsContactDataMain.length > 0">
                    <b-card no-body class="p-3 mb-3 app-local-height" >                        
                        <div class="d-flex" >
                            <clients-profile-contacts-avatar-component :contacts="cItems" :ids_contact_data_main="cIdsContactDataMain" :idDraggable="false"/>
                        </div>                                        
                    </b-card>  
                </b-col> 
                
                <b-col cols="12" v-else>
                    <b-alert variant="warning" show class="p-3 mb-2 app-local-height d-flex align-items-center">
                        <div class="text-small">{{trans('select-contacts-from-list',191)}}</div>    
                    </b-alert> 
                </b-col> 
            </b-row>

            <template #overlay>
               <div></div>
            </template>

        </b-overlay>
        <b-row>
            <b-col cols="12">
                <b-card no-body class="app-local-card-size pl-3 pr-3 pt-2 pb-2">  
                    <div class="app-fixed-search d-flex align-items-center">
                        <div class="d-flex align-content-center justify-content-between w-100">
                            <b-input-group class="input-group-merge" >
                            
                                <b-input-group-prepend is-text>
                                    <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                    <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                                </b-input-group-prepend >

                                <b-form-input class="pl-2 mt-1 border-0" autofocus :placeholder="trans('search',3)" v-model="filters.search" :lazy="true"/>

                            </b-input-group>
                        </div>
                    </div>

                    <hr class="app-search-modal-separator">

                    <b-row class="ml-1 mr-1 mt-2 pb-2 app-local-row-header-font" align-v="center" >
                        <b-col start>           
                        </b-col>
                        <b-col cols="1" class="app-width-min-40 pl-1">
                            <span v-overflow-tooltip  v-b-tooltip.hover.top :title="this.trans('cp-planning',192)">P</span>
                        </b-col>
                        <b-col cols="1" class="app-width-min-40 pl-1">
                            <span v-overflow-tooltip  v-b-tooltip.hover.top :title="this.trans('sh-hours',215)">U</span>
                        </b-col>
                        <b-col cols="1" class="app-width-min-40 pl-0 pr-1">
                            <span v-overflow-tooltip v-b-tooltip.hover.top :title="this.trans('placment',5)">PL</span>
                        </b-col>
                    </b-row>

                    <b-row v-if="items.length == 0">
                        <b-col v-if="!loading">
                            <div class="pt-2 pl-2 d-flex justify-content-center"><span class="app-table-no-records">Geen resultaten gevonden</span></div>
                        </b-col>
                    </b-row>

                    <b-list-group flush class="app-local-list-group-custom-style" :style="'max-height:'+listHeight+';min-height:'+listHeight">
                        <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in cItems" :key="item.id_contact_data_main" tabindex="0" @click.prevent="selectItem($event, item)">
                            <b-row class="w-100">
                                <b-col start>
                                    <b-form-checkbox v-model="item.checked" value="1" unchecked-value="0" size="sm" :class="item.checked == 1 ? 'custom-checkbox-checked' : ''">
                                        <span class="app-documents-item">{{item.name}}</span>
                                    </b-form-checkbox>
                                </b-col>

                                <b-col  cols="1" class="app-width-min-40">
                                    <app-check-box v-model="item.planning" class="custom-disabled" :disabled="true"/>
                                </b-col>
                                <b-col  cols="1" class="app-width-min-40">
                                    <app-check-box v-model="item.hours" class="custom-disabled" :disabled="true"/>
                                </b-col>
                                <b-col  cols="1" class="app-width-min-40">
                                    <app-check-box v-model="item.placement" class="custom-disabled" :disabled="true"/>
                                </b-col>
                           
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                </b-card>
            </b-col> 
        </b-row>


    </div>
</template>

<script>
import ClientsProfileContactsAvatarComponent from './ClientsProfileContactsAvatarComponent.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";

export default {

    components: {
        ClientsProfileContactsAvatarComponent
    },

    props: {
        idsContactDataMain: {},
        idClientDataMain: { },     
        listHeight: {type: String, default: "140px"},
        listType: {type: String},
        addButtonLabel: {type: String, default: null},
        itemsCount: {type: Number}
    },
    

    mounted() {
        this.getList();
    },

    watch:{
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },
    },

    computed: {
       
        cIdsContactDataMain: {
            get() { return this.idsContactDataMain },
            set(value) {this.$emit('update:ids_contact_data_main', value) }
        },

        cItems: {
            get() { 
                     var list_items = [];
                     this.items.forEach(item => {  
                       if (this.idsContactDataMain.includes(item.id_contact_data_main.toString())) {
                            item.checked =  "1";
                        } else {
                            item.checked = "0";
                        }
                        list_items.push(item);
                    });
                    return list_items; 
                }
        },       
    
    },

    data(){
        return{
            filters:{ 
                search:"",
                sort_by: "name",
                sort_desc: false,
                args: []
            },
            items:[],
            loading: false,

            alertClass: new AppAlerts()
        }
    },

    methods: {

        getList(){
            this.loading = true;
            axios
                .post("clients/profile/contact/getList", {
                    id_client_data_main: this.idClientDataMain,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data;
                    this.$emit('update:itemsCount', this.items.length);
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        selectItem(event, item){
            if( event == null || event.target.className != "dropdown-item"){
                if( this.cIdsContactDataMain.includes(item.id_contact_data_main.toString()) ) {
                    
                    if(this.listType == "organization") { 
                        item.total_contact_client--;    
                    } else if( this.listType == "location") { 
                        item.total_contact_location--;    
                    } else if( this.listType == "department") {
                        item.total_contact_department--;    
                    }
                              
                    this.cIdsContactDataMain.splice(this.cIdsContactDataMain.indexOf(item.id_contact_data_main.toString()), 1);
                } else {

                    if(this.listType == "organization") { 
                        item.total_contact_client++;    
                    } else if( this.listType == "location") { 
                        item.total_contact_location++;    
                    } else if( this.listType == "department") {
                        item.total_contact_department++;    
                    }
                    
                    this.cIdsContactDataMain.push(item.id_contact_data_main.toString());
                }
            }
        },
        
    },

}
</script>

<style scoped>
.app-local-card-size{
   display: flex;
   align-items: stretch;
}

.app-local-dropdown{
    width:65px
}

.app-local-list-group-custom-style{
    overflow-y: auto;
}

.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}

.input-group-text {
    border: none;
}

.card-body {
    padding-top: 0.5rem;
}


.app-local-height {
    min-height:60px !important;
}

.app-local-contacts-avatar-component{
    min-height:70px;
    max-height:70px;
}

.app-local-avatar {
    margin-left: calc(-0.1575rem); 
    margin-right: calc(-0.1575rem); 
    width: 28px; 
    height: 28px; 
    border: 2px solid white; 
    font-size: 0.7rem;
    background-color:rgba(173, 171, 171, 0.466) !important;
}


.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.checkbox-green .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #12B76A !important;
}


</style>