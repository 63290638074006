<template> 
    <b-modal ref="modal-client-profile-structure-location-change" size="md" v-model="computedShow" no-close-on-backdrop title="CAO">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col>
                        <b-alert variant="warning" show class="mb-3">
                            <div class="text-md-start mb-2"> {{trans('cao-consequences-allert',191)}}</div> 
                        </b-alert> 
                        <app-row-left-label :label="trans('cao',182)">
                            <app-select type="getSectionList" v-model="cArgs.section" :validatorRules="cArgs.list_type == 'client_profile' ? 'required' : ''" validatorName="CAO" :clearable="cArgs.list_type != 'client_profile'"/>
                        </app-row-left-label>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="changeCAO" type="primary" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],


    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) {this.$emit('update:return', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {

        async changeCAO() {
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }
                    
                this.loading = true;

                 axios
                    .post("clients/profile/cao/change", {
                        profile_id: this.cArgs.profile_id,
                        section: this.cArgs.section == null ? null : this.cArgs.section.value,
                        return_type: this.cArgs.list_type
                    })
                    .then((res) => {
                        this.cReturn = res.data;                  
                        this.$emit("update:result", 1);
                        this.$emit("update:show", false);                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    }); 
    
            })
                
        },

        closeModal() {
            this.$emit("update:result", -1);
            this.$emit("update:show", false);
        },

    },
};
</script>
<style scoped>

</style>

